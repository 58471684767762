'use client';
import Image from 'next/image';

import EngieneSvg from '@/svg/product/engiene.svg';
import YearSvg from '@/svg/product/year.svg';
import HotIcon from '@/svg/product/hot.svg';
import DriveTypeSvg from '@/svg/product/driveType.svg';
import TransmissionSvg from '@/svg/product/transmission.svg';
import OdometrSvg from '@/svg/product/odometr.svg';
import FuelSvg from '@/svg/product/fuel.svg';
import DefaultButton from '@/components/Main/shared/DefaultButton';
import { useEffect } from 'react';
import { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

export default function Product({ car, hot = false }) {
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState('/src/imgs/picture_plug.jpg');
  useEffect(() => {
    const getPhoto = async () => {
      try {
        const res = await fetch(`/api/photo?id=${car._id}`);
        const { photo } = await res.json();
        setPhoto(photo);
        setLoading(true);
      } catch (error) {
        console.error('Product photo error :>> ', error);
      }
    };

    getPhoto();
  }, []);
  return (
    <div className="product">
      {hot && (
        <div className="hot-icon">
          <HotIcon />
        </div>
      )}
      <div className="img">
        {loading ? (
          <Image
            src={photo}
            alt="holder_car"
            fill
            sizes="100%"
            loading="lazy"
          />
        ) : (
          <div className="spinner-block">
            <ClipLoader />
          </div>
        )}
      </div>
      <div className="body">
        <div className="title">{car.name}</div>
        <div className="tags">
          <div className="tag">
            <YearSvg /> Год: <span className="text-primary">{car.year}</span>
          </div>
          <div className="tag">
            <EngieneSvg /> Объем:{' '}
            <span className="text-primary">{car.engiene}л</span>
          </div>
          <div className="tag">
            <DriveTypeSvg /> <span>Привод:</span>
            <span className="text-primary">{car.drive_type}</span>
          </div>
          <div className="tag">
            <TransmissionSvg /> <span>Коробка:</span>
            <span className="text-primary">{car.transmission}</span>
          </div>
          <div className="tag">
            <FuelSvg /> <span>Топливо:</span>
            <span className="text-primary">{car.fuel}</span>
          </div>
          <div className="tag">
            <OdometrSvg /> <span>Пробег:</span>
            <span className="text-primary">{car.odometr} км</span>
          </div>
        </div>

        <div className="price">
          Цена под ключ РФ <span>от {formatPrice(car.key_price)}₽</span>
        </div>
        <div className="btn">
          <DefaultButton
            text="Заказать авто"
            fill
            cb={{
              type: 'openModal',
              args: `Интересуется: ${car.name} (${car.key_price} $)`,
            }}
          />
        </div>
      </div>
    </div>
  );
}

function formatPrice(price) {
  if (!price) return 'нет цены';
  var priceString = price.toString();
  var formattedArray = [];
  for (var i = priceString.length - 1; i >= 0; i--) {
    // Добавляем текущий символ в начало массива разделенных разрядов
    formattedArray.unshift(priceString[i]);

    // Если индекс текущего символа делится на 3 без остатка и это не последний символ, добавляем пробел в массив
    if ((priceString.length - i) % 3 === 0 && i !== 0) {
      formattedArray.unshift(' ');
    }
  }
  var formattedPrice = formattedArray.join('');
  return formattedPrice;
}
