'use client';
import Image from 'next/image';
import maksimManager from '@/imgs/managers/maksim.jpg';
import bogdanManager from '@/imgs/managers/bogdan.jpg';
import andreiManager from '@/imgs/managers/andrei.jpg';
import vitaliy from '@/imgs/managers/vitaliy.jpg';
import yan from '@/imgs/managers/yan.jpg';
import oleg from '@/imgs/managers/oleg.jpg';
import daniil from '@/imgs/managers/daniil.jpg';
import PhoneSvg from '@/svg/managers/Phone_Manager.svg';
import TelegramSvg from '@/svg/socials/telega.svg';
import InstagramSvg from '@/svg/socials/whatsapp.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

const managers = [
  {
    name: 'Управляющий Богдан',
    phone: '+995-500-500-439',
    img: bogdanManager.src,
  },
  {
    name: 'Менеджер Андрей',
    phone: '+7 (931) 107-13-44',
    img: andreiManager.src,
  },
  {
    name: 'Менеджер Максим',
    phone: '+7 (958) 578-64-60',
    img: maksimManager.src,
  },
  { name: 'Менеджер Данил', phone: '+7 931 107 1382', img: daniil.src },
  { name: 'Контент менеджер Виталий', img: vitaliy.src },
  { name: 'Мастер приемщик Олег', phone: '+995 591 657 657', img: oleg.src },
  { name: 'Мастер приемщик Ян', phone: '+995 591 657 657', img: yan.src },
];

export default function Managers() {
  return (
    <div className="padd-container">
      <div className="white-bordered">
        <div className="managers">
          <h2 className="managers__title">НАШИ МЕНЕДЖЕРЫ</h2>
          <div className="managers__content md:px-[24px]">
            <Swiper
              modules={[Navigation]}
              spaceBetween={24}
              pagination={{ clickable: true }}
              slidesPerView={1}
              breakpoints={{
                400: {
                  slidesPerView: 2,
                },
                900: {
                  // На экране шириной от 768px
                  slidesPerView: 3, // Показывать 3 слайда
                },
                1100: {
                  slidesPerView: 4,
                },
              }}
              navigation
            >
              {managers.map((manager, i) => (
                <SwiperSlide>
                  <ManagerCard {...manager} key={i} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="managers__messagers">
            <div className="messagers__block">
              <p className="messagers__text">
                Нужна консультация? <br /> Наши менеджеры помогут вам
              </p>
              <div className="messagers__icons">
                <div className="btns">
                  <a target="_blank" href="https://wa.me/+79585786533">
                    <InstagramSvg />
                  </a>
                </div>
                <div className="btns">
                  <a target="_blank" href="https://t.me/autodeskusa">
                    <TelegramSvg style={{ color: 'white' }} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import Link from 'next/link';
function ManagerCard({ name, phone, img }) {
  return (
    <div className="manager">
      <div className="manager__img pt-[100%]">
        <Image src={img} fill alt="manager" />
      </div>
      <div className="manager__contacts">
        <h4 className="manager__title">{name}</h4>
        {phone && (
          <div className="manager__phone">
            <PhoneSvg />
            <Link href={`tel:${phone}`}>{phone}</Link>
          </div>
        )}
      </div>
    </div>
  );
}
